.twitter-typeahead .tt-query,
.twitter-typeahead .tt-hint {
    margin-bottom: 0;
}

.tt-dropdown-menu {
    background-clip: padding-box;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border: 1px solid rgba(0, 0, 0, 0.15);
    margin-top: 2px;
    min-width: 160px;
    padding: 5px 0;
    width: 100%;
    z-index: 1000;
}

.tt-suggestion {
    color: black;
    text-align: left;
    display: block;
    overflow: hidden;
    padding: 3px 12px;
    text-overflow: ellipsis;
}

.tt-suggestion p {
    margin: 0;
}

#services-search .tt-hint,
#main-body .tt-hint {
	display: none;
}

div.as_segmentation_homepage .tt-hint {
	color: white;
	height: 45px;
	padding: 10px 12px;
	top: inherit !important;
	width: 100% !important;
}

#main-body span.twitter-typeahead,
div.as_segmentation_homepage span.twitter-typeahead {
	display: block !important;
}

input.tt-query {
	font-size: 18px;
	height: 45px;
}

#main-body input.tt-query {
	height: 32px;
}

.tt-predefined .tt-predefined-header {
    font-size: 85%;
    font-weight: bold;
    padding: 5px;
    color: #999;
}

.tt-predefined .tt-suggestions {
    cursor: pointer;
}
